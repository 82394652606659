import axios from "axios";
import { URL_Clinic } from "../../../Services/clinicService";

export default class BasicAppState {
    url;
    static clinic;

    static TypeClinic = Object.freeze({
        Odontologia: 1,
        Psicologia: 2,
        Estetica: 3,
        Barbearia: 4,
        Fisioterapia: 5,
        Pet: 6,
        SalaoBeleza: 7,
        Outros: 8,

        /**
         *  Opção para mecanismos de seleção pelo
         * método "por exclusão" das opções acima;
         * @param {TypeClinic[]} types - Tipos selecionados para exclusão; 
         * @returns {TypeClinic[]} Tipos resultantes da exclusão;
         */
        byDeletion: (types) => {
            const answer = [];
            for (const property of Object.getOwnPropertyNames(BasicAppState.TypeClinic))
                if (
                    typeof (BasicAppState.TypeClinic[property]) == 'number'
                    && types.indexOf(BasicAppState.TypeClinic[property]) == -1
                ) {
                    answer.push(BasicAppState.TypeClinic[property]);
                }
            return answer;
        }
    });

    async getClinic() {
        let retorno;
        if (!this.clinic) {
            retorno = await axios.get(`${URL_Clinic}/GetById`);
            this.clinic = retorno.data;
            // this.clinic.imageFile = await BasicAppState._loadLogo();
        }
        return this.clinic;
    }

    static async _loadLogo() {
        return (await axios.get(`${URL_Clinic}/GetLogoById`));
    }

    async getTypeCompany() {
        const clinic = await this.getClinic();
        return clinic.typeClinic;
    }

    getIdProfile() {
        return localStorage.getItem("idProfile");
    }
}

