
import React, { Component } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import { history } from './history'
import Axios from 'axios';
import { jwtDecode } from "jwt-decode";
class App extends Component {
  render() {
    Axios.interceptors.request.use(async (config) => {
      let app_token = JSON.parse(localStorage.getItem('app-token'));
      if (app_token != null) {

        if (jwtDecode(app_token.token).exp < Date.now() / 1000) {
          localStorage.clear();
          sessionStorage.clear();
          history.push('/login')
        }
      }

      let items = JSON.parse(localStorage.getItem('ClinicData'))
      let id = items != null ? parseInt(items.id) : 0

      if (config.url.endsWith('api/clinic')) {
        //passa o id do usuário para alterações em dados da clínica
        items = JSON.parse(localStorage.getItem('app-token')) ?? ''
        id = parseInt(items.id)
      }

      let tenantid = config.params != undefined ? config.params['tenantid'] ?? '' : '';

      config.headers = {
        'Authorization': app_token != null ? `Bearer ${app_token.token}` : '',
        'tenantid': tenantid != '' ? tenantid : id,
      }

      return config;
    }, (error) => {
      console.log(error, 'errorintercept');

      // I cand handle a request with errors here
      return Promise.reject(error);
    });
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename = process.env.NODE_ENV === 'development' ? '/' : (PUBLIC_URL || '/');

    return (
      <Router basename={basename} history={history}>
        <Routes />
      </Router>
    );

  }
}
export default App;
