
import Axios from "axios";
import { AppConfiguration } from "read-appsettings-json";
export const URL_Clinic = (`${process.env.REACT_APP_HOST}/clinic`)
export const URL_Clinic_Filter = (`${AppConfiguration.Setting().apiendpoint}/clinic/filter`)
export const URL_HealthPlanClient = (`${AppConfiguration.Setting().apiendpoint}/healthplanclient`)

export function DadosClinic(query) {
    return new Promise(async (resolve, reject) => {
        await Axios.get(
            `${URL_Clinic}/GetByName/${query}`,
        )
            .then((response) => {
                resolve(
                    response.data.map(({ id, name }) => ({
                        value: id,
                        name: name,
                    })),
                );
            })
            .catch(reject);
    });
}